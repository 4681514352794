<template>
  <label
    :for="'file_' + itemKey"
    class="flex items-center justify-center p-2 border-2 border-dashed rounded-md cursor-pointer w-100 hover:opacity-100"
  >
    <div
      v-if="!busy && !value"
      class="flex flex-col items-center text-xs text-gray-800"
    >
      <svg
        class="w-8 h-8 text-blue-200"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <div>
        <span class="text-blue-500"
          >Seleccionar una imágen haciendo click,</span
        >
        o arrastrarlar aquí.
      </div>
      <div>PNG, JPG, GIF up to 10MB</div>
    </div>
    <svg
      v-if="busy"
      class="w-5 h-5 ml-1 mr-2 text-white transition duration-150 ease-in-out animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>

    <input
      type="file"
      :name="'file_' + itemKey"
      :id="'file_' + itemKey"
      @change="upload"
      class="hidden form-control-file"
    />
    <img v-if="value" class="w-32 h-auto rounded" :src="value" />
  </label>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    itemKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    status() {
      return this.value;
    },
  },
  mounted() {
    const self = this;
    this.reader = new FileReader();
    this.reader.addEventListener(
      "load",
      function (e) {
        self.busy = true;
        var form = new FormData();
        form.append("file", e.target.result);
        form.append("upload_preset", "ml_default");
        axios({
          url: `https://api.cloudinary.com/v1_1/de3yblyup/image/upload`,
          method: "POST",
          async: true,
          crossDomain: true,
          headers: {
            "Content-Type": undefined,
            "X-Requested-With": "XMLHttpRequest",
            "cache-control": "no-cache",
          },
          mimeType: "multipart/form-data",
          data: form,
        })
          .then((res) => {
            self.busy = false;
            if (res.status === 200) {
              console.log(res.data);
              self.$emit("input", res.data.url);
              self.$emit("inputExtra", {
                url: res.data.url,
                public_id: res.data.public_id,
                version: res.data.version,
              });
            } else {
              console.info("oops, something went wrong", res);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      false
    );
  },
  methods: {
    upload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.reader.readAsDataURL(files[0]);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss" scoped></style>
