import { render, staticRenderFns } from "./WHtml.vue?vue&type=template&id=c882c5a2&"
import script from "./WHtml.vue?vue&type=script&lang=js&"
export * from "./WHtml.vue?vue&type=script&lang=js&"
import style0 from "./WHtml.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WTextarea from '@/components/WTextarea.vue'
autoInstallComponents(component, {WTextarea})
