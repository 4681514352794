<template>
  <div class="mb-2">
    <label class="block text-sm font-medium leading-5 text-gray-700">{{ label }}</label>
    <w-textarea :value="value" @input="$emit('input', $event)"></w-textarea>
    <!-- <vue-editor :editorToolbar="customToolbar" :value="value" @input="$emit('input', $event)"></vue-editor> -->

    <!-- <editor name="html" ref="toastuiEditor"  :options="editorOptions"     initialEditType="wysiwyg" height="200px" class="h-32 text-xs rounded" :initial-value="value" @change="input" /> -->
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
// import 'codemirror/lib/codemirror.css';
// import '@toast-ui/editor/dist/toastui-editor.css';
//  import { VueEditor } from "vue2-editor";
// import { Editor } from '@toast-ui/vue-editor';

export default {
  components: {
    // VueEditor
  },
  props: ["description", "label", "placeholder", "value"],
  data() {
    return {
      html: "",
      customToolbar: [
        ["bold", "italic", "underline", "strike", { color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  async mounted() {
    this.html = this.value;
  },
  // watch: {
  //   value(value) {
  //     this.$refs.toastuiEditor.invoke('setHtml', value);
  //   }
  // },
  methods: {
    input() {
      // let html = this.$refs.toastuiEditor.invoke('getHtml');
      // this.$emit("input", html)
    },
  },
};
</script>

<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.te-editor {
  // margin-top: 10px;
}
.te-ww-container .tui-editor-contents {
  padding: 16px 25px 0px 25px !important;
}
.ql-snow .ql-toolbar button svg,
.quillWrapper .ql-snow.ql-toolbar button svg {
  width: 16px !important;
  height: 16px !important;
}
.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin-bottom: 4px !important;
}
</style>
