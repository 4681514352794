<template>
<div class="flex items-center">
  <div class="flex items-center">
      <div
    :class="[inputValue ? 'bg-green-400' : 'bg-grey-500', sizeClass]"
    class="inline-block transition border border-gray-400 rounded-full cursor-pointer"
    @click="toggle()"
  >
    <div
      :style="{ transform: `translateX(${inputValue ? 100 : 0}%)` }"
      class="w-1/2 h-full transition bg-white rounded-full shadow"
    />
  </div>
  <label for="input" class="block ml-2 text-sm font-medium leading-5 text-gray-700">{{label}}</label>
</div>
  <div class="text-xs text-gray-600">{{description}}</div>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "sm"
    },
    description: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      status: false
    };
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case "xs":
          return "w-6 h-3";
        case "sm":
          return "w-8 h-4";
        case "md":
          return "w-12 h-6";
        case "lg":
          return "w-20 h-10";
        default:
          return "w-8 h-4";
      }
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    toggle() {
      this.inputValue = !this.inputValue;
    }
  }
};
</script>
