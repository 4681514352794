import { render, staticRenderFns } from "./WDropdownMenu.vue?vue&type=template&id=51ad2c98&scoped=true&"
import script from "./WDropdownMenu.vue?vue&type=script&lang=js&"
export * from "./WDropdownMenu.vue?vue&type=script&lang=js&"
import style0 from "./WDropdownMenu.vue?vue&type=style&index=0&id=51ad2c98&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ad2c98",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WMenuItems from '@/components/WMenuItems.vue'
autoInstallComponents(component, {WMenuItems})
