<template>
  <div class="mb-2">
    <label
      for="input"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ label }}</label
    >
    <textarea
      :value="value"
      rows="6"
      @input="$emit('input', $event.target.value)"
      id="input"
      class="block w-full px-2 py-1 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5"
      :placeholder="placeholder"
    ></textarea>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: ["description", "label", "placeholder", "value"],
};
</script>

<style lang="scss" scoped>
</style>