<template>
  <div class="relative block md:hidden">
    <button
      @click="show = !show"
      class="transition duration-150 ease-in-out border rounded"
    >
      <svg
        class="w-6 h-6 p-1 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
    <div
      v-if="show"
      class="absolute right-0 z-20 w-40 origin-top-right rounded-md shadow-lg bg-base-900"
    >
      <w-menu-items class="mt-0"></w-menu-items>
    </div>
  </div>
</template>

<script>
import WMenuItems from './WMenuItems.vue'
// import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  components: { WMenuItems },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<style lang="css" scoped>
.popup:after {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  /* width: 20px;
  height: 20px; */
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  /* box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5); */
  z-index: -1;
  top: -7px;
  right: calc(0% + 10px);
}
</style>
