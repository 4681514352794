<template>
  <div
    class="flex items-center justify-between h-16 px-5 border-b border-sand-600"
  >
    <div class="flex items-center">
      <div class="mr-2 border rounded" v-if="back">
        <svg
          @click="$router.push(back)"
          class="w-6 h-6 p-1 text-gray-500 cursor-pointer hover:bg-base-100"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </div>
      <div>
        <slot />
      </div>
    </div>
    <w-dropdown-menu v-if="!hideMenu" class="print:hidden"> </w-dropdown-menu>
  </div>
</template>

<script>
import WDropdownMenu from './WDropdownMenu.vue'
  export default {
  components: { WDropdownMenu },
    props: ["back", "hideMenu"]
  }
</script>

<style lang="scss" scoped>
</style>