<template>
  <div>
    <w-top-bar>Detalles de la Categoria</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="flex w-full p-5 mb-2 text-sm bg-white rounded-md shadow">
              <div class="w-2/5 md:pr-4">
                <div class="text-lg font-medium">Información General</div>
                <div class="text-gray-700">
                  El código (key), es para uso interno del sistema y no será visible para los
                  visitantes.
                </div>
              </div>
              <div class="flex-grow">
                <w-input label="Nombre" v-model="clone.label" />
                <w-input label="Código (key)" v-model="clone.key" />
                <w-html label="Bloque HTML de Cabecera" v-model="clone.header" />
                <div class="mt-5">
                  <w-switch label="Categoría del Home" v-model="clone.homeCategory"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Si se activa, se mostrará la categoria en la página princial
                  </div>
                </div>
                <div class="mt-5">
                  <w-switch label="Bloque HTML Público" v-model="clone.headerPublic"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Todos los visitantes, pueden ver el bloque.
                  </div>
                </div>
                <div class="mt-5">
                  <w-switch label="Visible" v-model="clone.visible"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Mostrar la categoria en el menu de la tienda.
                  </div>
                </div>
                <div class="mt-5">
                  <w-switch label="Envios" v-model="clone.shipping"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    ¿La categoria es de productos que se enviarán por correo?
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full p-5 text-sm bg-white rounded-md shadow">
              <div class="w-2/5 md:pr-4">
                <div class="text-lg font-medium">Open Graph</div>
                <div class="text-gray-700">
                  Campos para utilizar en los meta-tags en los enlaces compartidos en redes
                  sociales. Si los campos están vacíos, se utilizaran los globales por defecto.
                </div>
              </div>
              <div class="flex-grow">
                <w-input label="Titulo de Página" v-model="clone.og_title" />
                <w-html label="Descripción" v-model="clone.og_description" />
                <label class="block text-sm font-medium leading-5 text-gray-700">Imágen</label>
                <w-image-upload @input="clone.cloudImage = $event" v-model="clone.og_image" class="mb-5" label="Imágen" />
              </div>
            </div>

            <div class="flex justify-end py-2">
              <div v-if="saved" class="flex items-center text-sm font-medium text-green-600">
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div v-if="saving" class="flex items-center text-sm font-medium text-gray-600">
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper } from "feathers-vuex";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "EditCategories",
  components: {
    FeathersVuexFormWrapper,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Categories } = this.$FeathersVuex.api;

      return this.id === "new" ? new Categories() : Categories.getFromStore(this.id);
    },
    productsParams() {
      return {
        query: {
          // type: { $in: ["books", "magazine"] },
          $sort: {
            name: 1,
          },
          $limit: 300,
          $select: ["name", "_id", "type"],
        },
      };
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { Categories } = this.$FeathersVuex.api;
        const existingRecord = Categories.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Categories.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      saved: false,
      saving: false,
    };
  },
  methods: {
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedCategories) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedCategories);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedCategories._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
